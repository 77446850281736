<template>
    <div>
        <section id='SupportingCaregivers' class="content-container" name='Supporting Caregivers'>
            <div class="titles">
                <h2>Supporting Caregivers</h2>
            </div>
            <div class="col-12 px-3">
                <p>During the Circuit Breaker, centre-based care services were suspended except for designated centres that served clients without alternative arrangements. To alleviate the impact on caregivers, we <a href='/covid'>supported Community Care providers</a> in modifying operations and minimising service disruption. 
                <p>We also embarked on some initiatives to support caregivers directly during the COVID-19 period.</p>
            </div>
        </section>

        <section id='KeepingSeniorsEngaged' class="content-container" name='Keeping Seniors Engaged'>
            <div class="titles">
                <h4>Keeping Seniors Engaged</h4>
            </div>
            <div class="col-12 secondary-image-container">
               <div class="img-line">
                    <img src="@/assets/support-public/public_3.jpg" alt="">
                    <p class="credits">Credit: Ministry of Health</p>
                </div>
            </div>
            <p class="caption px-3">Booklet activities included origami, colouring, drawing, and puzzles</p>
            <div class="col-12 px-3">
                <p>At the start of the pandemic, both caregivers and seniors had to adjust to a change of routine. Caregivers had to balance work, family and caregiving commitments, while seniors needed alternative activities to keep themselves engaged at home. </p>
                <p>AIC produced three activity booklets that seniors could complete in the safety and comfort of their homes. Titled ‘Makan Time’, ‘My Home, My Kampong’ and ‘Rolling Good Times’, each booklet contained 16 fun arts-based activities and puzzles, as well as important COVID-19 information. The booklets, together with mental wellness and dementia resources, were available <a href='http://www.aic.sg'>online</a> for seniors and caregivers to download and use. </p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-public/infographics_4.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>Over <span class="stats">60,000</span> copies of the booklets have been distributed at events and partners’ facilities.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id='LearnTogether' class="content-container" name='Learn Together with Me'>
            <div class="titles">
                <h4>Learn Together with Me</h4>
            </div>
            <div class="col-12 secondary-image-container">
               <div class="img-line">
                    <img src="@/assets/support-public/public_4.jpg" alt="">
                </div>
                <p class="caption">The 10 episodes AIC worked on with Mediacorp aired between 15 and 28 April 2020</p>
            </div>
            <div class="col-12 px-3">
                <p>We secured funding to collaborate with Mediacorp Channel 8 on Season 1 of their television programme ‘Learn Together with Me’ (乐学每疫刻).</p>
                <p> This public education programme conveyed important social messages in an entertaining manner. It reminded seniors on the importance of proper handwashing and social distancing. It also encouraged them to improve their physical and mental wellbeing through art activities, sing-alongs with celebrities, and indoor exercise such as dancing or simple workouts.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-public/infographics_6.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>‘Learn Together with Me’ reached an audience of <span class="stats">700,000</span>.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id='SupportMentalWellness' class="content-container" name='Support for Mental Wellness'>
            <div class="titles">
                <h4>Support for Mental Wellness</h4>
            </div>
            <div class="col-12 px-3">
                <p>In May 2020, AIC worked with Temasek Foundation to launch the microsite <a href='https://stayprepared.sg/mymentalhealth/'>My Mental Health</a>, which provided tips and resources to support one’s mental wellbeing during COVID-19. It was part of the Stay Prepared website, an initiative by Temasek Foundation to build community resilience in times of crisis.</p>
                <p>In addition to this microsite, we developed and uploaded other mental wellness and dementia resources to support caregivers and individuals. We extended phone triage support through CareInMind (careinmind@aic.sg) during the Circuit Breaker for those who needed help. CareInMind care coordinators also worked with MOH and community mental health service partners to provide assessment, counselling and referrals to relevant community services for persons under quarantine.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-public/infographics_5.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>My Mental Health has received over <span class='stats'>170,000</span> page views.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id='SPOC-19' class="content-container" name='Support for Persons living with dementia Over the COVID-19 period (SPOC-19)'>
            <div class="titles">
                <h4>Support for Persons living with dementia Over the COVID-19 period (SPOC-19)</h4>
            </div>
            <div class="col-12 secondary-image-container">
               <div class="img-line">
                    <img src="@/assets/support-public/public_5.jpg" alt="">
                </div>
                <p class="caption">The ICED sticker by AIC was one of three identifiers recognised under this initiative</p>
            </div>
            <div class="col-12 px-3">
                <p>From May 2020 to April 2021, we collaborated with the Alzheimer’s Disease Association on SPOC-19.</p>
                <p>This initiative helped caregivers and families who were concerned that their loved ones living with dementia might unintentionally flout precautionary measures. Persons living with dementia may lack the cognitive ability to fully understand the COVID-19 situation and not comply with measures like safe distancing or wearing a mask.</p>
                <p>SPOC-19 introduced three ‘identifiers’ that persons living with dementia and their caregivers could register for. Members of the public or ground enforcement authorities could then recognise this vulnerable group easily through these identifiers and offer appropriate assistance.</p>
            </div>
        </section>

        <section id='AICHotline' class="content-container" name='AIC Hotline'>
            <div class="titles">
                <h4>AIC Hotline</h4>
            </div>
            <div class="col-12 secondary-image-container">
               <div class="img-line">
                    <img src="@/assets/support-public/public_6.jpg" alt="">
                </div>
            </div>
            <div class="col-12 px-3">
                <p>We publicised the AIC hotline (1800-650-6060) during the pandemic so that seniors and caregivers who needed information or assistance could receive support. We also extended the hotline’s operation hours during the Circuit Breaker.</p>
            </div>
        </section>

        <section id='SupportChronicIllness' class="content-container" name='Support for People with Chronic Illness'>
            <div class="titles mt-4">
                <h4>Support for People with Chronic Illness</h4>
            </div>
            <div class="col-12 secondary-image-container">
               <div class="img-line">
                    <img src="@/assets/support-public/public_7.jpg" alt="">
                </div>
                <p class="caption">A PCN nurse counselling a patient virtually</p>
            </div>
            <div class="col-12 px-3">
                <p>In support of safe distancing measures, AIC extended <a href='/support-seniors#CommunityHealthAssistScheme'>Community Health Assist Scheme (CHAS)</a> subsidies and MediSave in 2020 to those following up on their chronic illness via video consultations. CHAS patients with stable conditions did not need to visit clinics at the height of COVID-19, and they could get others to collect medication on their behalf.</p>
                <p> Video consultations were also extended to <a href='/support-seniors#PrimaryCareNetworks'>Primary Care Network (PCN)</a> nurse counsellors to help patients manage their chronic conditions.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-public/infographics_7.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>As of December 2020, over <span class="stats">200</span> nurse counselling sessions were conducted through video consultation.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'SupportingCaregivers',
}
</script>
