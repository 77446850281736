<template>
    <section class="content-container row justify-content-center" id='description' name='Support for seniors &amp; the public'>
        <div class="col-12 px-3">
            <p>The Circuit Breaker posed greater challenges for seniors, especially those who live alone, have poor family support or mobility issues. They were not able to tap on usual social support structures such as visits by befrienders or volunteers. Seniors who were active in the community could not meet friends at their usual hangouts. COVID-19 precautionary measures also caused disruptions to caregivers and their loved ones receiving some Community Care services. In response, AIC rolled out initiatives to help people stay active, safe and well during this challenging period.</p>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Description',
}
</script>
