var level_3_0_0 = {
    "0" : {
      "name": "Silver Generation Office (SGO) Outreach",
      "hash": "SupportingVulnerableSeniors"
    },
    "1" : {
      "name": "Circuit Breaker Meals-On-Wheels",
      "hash": "CircuitBreaker"
    },
};

var level_3_0_1 = {
    "0" : {
      "name": "Keeping Seniors Engaged",
      "hash": "KeepingSeniorsEngaged"
    },
    "1" : {
      "name": "Learn Together with Me",
      "hash": "LearnTogether"
    },
    "2" : {
      "name": "Support for Mental Wellness",
      "hash": "SupportMentalWellness"
    },
    "3" : {
      "name": "Support for Persons living with dementia Over the COVID-19 period (SPOC-19)",
      "hash": "SPOC-19"
    },
    "4" : {
      "name": "AIC Hotline",
      "hash": "AICHotline"
    },
    "5" : {
      "name": "Support for People with Chronic Illness",
      "hash": "SupportChronicIllness"
    },
};

var level_2_0 = {
  '0' : {
    name : 'Supporting Vulnerable Seniors',
    hash : 'SupportingVulnerableSeniors',
    links : level_3_0_0 
  },
  '1' : {
    name : 'Supporting Caregivers',
    hash : 'SupportingCaregivers',
    links : level_3_0_1 
  },
}

var level_1 = {
  '0' : {
    name: 'Support for the seniors & the public',
    sections: level_2_0,
    active: false
  },
}

export default level_1; 