<template>
    <div>
        <section id='SupportingVulnerableSeniors' class="content-container" name='Supporting Vulnerable Seniors'>
            <div class="titles">
                <h2>Supporting Vulnerable Seniors</h2>
                <h4>Silver Generation Office (SGO) Outreach</h4>
            </div>
            <div class="col-12 secondary-image-container">
               <div class="img-line">
                    <img src="@/assets/support-public/public_1.jpg" alt="">
                </div>
                <p class="caption">SGO volunteers running errands for a senior who was unable to leave home</p>
            </div>
            <div class="col-12 px-3">
                <p>To support the wellbeing of vulnerable seniors during the COVID-19 outbreak, 
                    <a href='/support-seniors#SilverGenerationOffice'>SGO</a>
                     reached out to them in late February 2020 to remind them to stay home, educate them on precautionary measures, and connect those with urgent needs to the relevant support. 
                </p>
                <p>When the Circuit Breaker commenced in early April 2020, SGO partnered JTC Corporation to distribute food packs to vulnerable seniors so that they could stay home and avoid exposure to COVID-19. SGO replaced home visits with weekly or fortnightly tele-engagements to continue monitoring the wellbeing of vulnerable seniors. They also helped seniors cope with Circuit Breaker measures by assisting them with simple errands such as the purchase of groceries and collection of medication, so that they need not leave their homes. </p>
                <p>Beyond the Circuit Breaker, SGO continues to provide the support seniors require in the new normal. We continue to tele-engage vulnerable seniors and have expanded the audience to include elderly caregivers, seniors with frailty or poor mobility, and those who need caregiving support. SGO is also working with the Infocomm Media Development Authority and the SG Digital Office on the Seniors Go Digital initiative to help seniors pick up digital skills, e.g. using WhatsApp, YouTube and TraceTogether.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row line">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-public/infographics_1.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>SGO reached out to around <span class="stats">27,000</span> seniors in late February 2020, and distributed food packs to over <span class="stats">30,000</span> seniors in April.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-public/infographics_2.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                             <p>SGO’s tele-engagement initiative started with about <span class="stats">21,000</span> seniors, and has grown to around <span class="stats">100,000</span> seniors.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id='CircuitBreaker' class="content-container" name='Circuit Breaker Meals-On-Wheels'>
            <div class="titles">
                <h4>Circuit Breaker Meals-On-Wheels</h4>
            </div>
            <div class="col-12 secondary-image-container">
               <div class="img-line">
                    <img src="@/assets/support-public/public_2.jpg" alt="">
                </div>
                <p class="caption">This temporary service supported seniors who had limited or no access to food during the Circuit Breaker</p>
            </div>
            <div class="col-12 px-3">
                <p>During Circuit Breaker, seniors were encouraged to stay at home and avoid community exposure. Circuit Breaker Meals-On-Wheels was activated, and AIC worked with the Ministry of Health (MOH), the Ministry of Social and Family Development, the Ministry of Culture, Community & Youth, the National Council of Social Service and community care partners to identify at-risk seniors to receive meal support.</p>
                <p>We set up a team in April 2020 to manage the overall operations of this initiative, and were supported by SGO, whose teams on the ground could identify those who needed the service. We also trained the appointed food caterer to identify ‘red flags’ and alert us if clients had not collected the previous two meals. We would then activate partners to check on them.</p>
            </div>
            <div class="col-12">
                <div class="table-diagram">
                    <div class="row">
                        <div class="col-4 diagram-img">
                            <img src="@/assets/support-public/infographics_3.png" alt="">
                        </div>
                        <div class="col-8 text-2">
                            <p>Circuit Breaker Meals-On-Wheels delivered an average of <span class="stats">2,600</span> daily meals. </p>
                            <p>As Circuit Breaker drew to a close, over <span class="stats">400</span> clients were identified to require long-term meal service.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'SupportingVulnerableSeniors',
}
</script>
<style>
.line{
	border-bottom: 2px solid white;
}
</style>
