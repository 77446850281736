<template>
    <div id="support-public" :class='fontsize'>
    
		<Intro :intro="intro"/>

        <div class="container-fluid secondary-section fontsize" id="secondary-content">
            <div class="row">
             
              <SubNavOne :navLinks='navLinks'/>

                <section id='Navigation' class="content ml-auto ">

                    <Description/>

                    <SupportingVulnerableSeniors/>

                    <SupportingCaregivers/>

                </section>

                <PageNavigation prev='/covid' next='/support-seniors' />

            </div>
        </div>
    </div>  
</template>

<script>
// @ is an alias to /src
import SubNavOne from '@/components/SubNavOne.vue'
import NavLinks from '@/components/support-public/navLinks.js'

import Intro from '@/components/Intro.vue'
import Description from '@/components/support-public/Description.vue'
import SupportingVulnerableSeniors from '@/components/support-public/SupportingVulnerableSeniors.vue'
import SupportingCaregivers from '@/components/support-public/SupportingCaregivers.vue'
import PageNavigation from '@/components/PageNavigation.vue'

import dynamicFontSizeMixin from '@/mixin/dynamicFontSizeMixin'

export default {
    name: 'LookingAhead',

    data() {
      return {
        scroll: 0,
            sections: '',
            currentSec: '',
            navLinks : NavLinks,
            hashes: [],
            intro: {
                title: 'Sustaining care through adversity',
                secondTitle: 'Support for seniors & the public',
                img: this.mobileAndTabletCheck() ? require("@/assets/support-public/COVID_Public_Mobile_Banner.jpg") : require("@/assets/support-public/COVID_Public_Desktop_Banner.jpg")
            }
        }
    },

    mixins: [dynamicFontSizeMixin],

    components: {
        SubNavOne,
        Intro,
        Description,
        SupportingVulnerableSeniors,
        SupportingCaregivers,
        PageNavigation,
    },
}
</script>

<style>
 /* media */
@media (min-width: 768px) {
	.content{
	 	width: 70vw;
	}
}
</style>